
  <app-navbar></app-navbar>

  <ng-container #vc></ng-container>
  <app-base-sub-navbar-container></app-base-sub-navbar-container>
  <div>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </div>



