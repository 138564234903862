import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, Event} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TranslateService} from '../../../core/translations/translate.service';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: [],
  standalone: false
})
export class BreadcrumbsComponent implements OnInit {
  readonly home = {icon: 'pi pi-home', title: 'bh'};
  menuItems: MenuItem[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this.router.events
      .pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
      });
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): any {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      if (routeURL !== '') {

        if (routeURL === 'bh') {
          breadcrumbs.push({label: this.translateService.translate('common.home'), title: '', icon: 'pi pi-home'});
          return this.createBreadcrumbs(child, url, breadcrumbs);
        }
        breadcrumbs.push({label: this.translateService.translate('common.' + routeURL), title: routeURL});
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return '';
  }

  itemClicked(menuItem: MenuItem): void {
    this.router.navigate(['bh/' + menuItem.title]);
  }

}
