/* eslint-disable max-len */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './core/login/login.component';
import {AuthGuard} from './core/auth/auth-guard.service';
import {HomeComponent} from './core/home/home.component';
import {UserSelfManagementComponent} from './components/user-self-management/user-self-management.component';
import {PasswordResetComponent} from './core/password-reset/password-reset.component';
import {NewPasswordComponent} from './core/new-password/new-password.component';
import {PasswordChangeComponent} from './core/password-change/password-change.component';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {AuthPreloadStrategy} from './core/auth/auth-preload-strategy';

const routes: Routes = [
  {path: '', redirectTo: 'bh', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {
    path: 'bh', component: HomeComponent, canActivate: [AuthGuard], children: [
      {path: 'profile', component: UserSelfManagementComponent},
      {path: 'password-change', component: PasswordChangeComponent},
      {path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(module => module.AdminModule)},
      {path: 'error-message', component: ErrorMessageComponent},
      {path: 'clients', loadChildren: () => import('./components/customers/customers.module').then(module => module.CustomersModule)},
      {path: 'offers', loadChildren: () => import('./components/offers/offers.module').then(module => module.OffersModule)},
      {path: 'products', loadChildren: () => import('./components/products/products.module').then(module => module.ProductsModule)},
      {path: 'orders', loadChildren: () => import('./components/orders/orders.module').then(module => module.OrdersModule)},
      {path: 'production', loadChildren: () => import('./components/production/production.module').then(module => module.ProductionModule)},
      {path: 'raw-materials', loadChildren: () => import('./components/raw-materials/raw-materials.module').then(module => module.RawMaterialsModule)},
      {path: 'warehouse', loadChildren: () => import('./components/warehouse/warehouse.module').then(module => module.WarehouseModule)},
      {path: 'mobile', loadChildren: () => import('./components/mobile/mobile.module').then(module => module.MobileModule)},
      {path: 'shipment', loadChildren: () => import('./components/shipment/shipment.module').then(module => module.ShipmentModule)}
    ]
  },
  {path: 'password-reset', component: PasswordResetComponent},
  {path: 'new-password', component: NewPasswordComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: AuthPreloadStrategy})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
