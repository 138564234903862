import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      const clonedRequest = req.clone({headers: req.headers.set('Authorization', this.authService.getCurrentAuthToken())});
      return next.handle(clonedRequest).pipe(
        map(response => {
          if (response instanceof HttpResponse) {
            if (response.headers.get('Authorization')) {
              const newToken: string | null = response.headers.get('Authorization');
              if (newToken) {
                this.authService.setAuthToken(newToken);
              }
            }
          }
          return response;
        })
      );
    }
    return next.handle(req);
  }
}
