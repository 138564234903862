<div class="modal fade" id="modalAbout" [ngStyle]="{'display': modalOpened ? 'block' : 'none', 'opacity': 1}">
  <div class="about__modal-dialog about__container">
    <div class="about__modal-content">
      <br>
      <form>
        <div class="about__modal-header">
          <h4> {{ 'common.application-version'| translate}} </h4>
        </div>
        <div
          class="about__modal-header"
          *ngFor="let key of getObjectKeys()">
          <h5> {{key}}: {{ version[key] }} </h5>
        </div>
        <div class="about__modal-footer">
          <button type="button" class="button__mat_raised_button"
                  (click)="toggleModal()">{{ 'common.close'| translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>


