<div class="form-details global-border global-border-radius global-flex-direction__column global-padding__small">
    <span class="global-title global-color--white"
    >{{ 'user-self-management.you-are-creating-qr-code-for-fast-pass' | translate }}</span>
    <span class="global-subtitle global-color--white"
    >{{ 'user.self-management.please-provide-your-credentials' | translate }}</span>

    <div class="form-details__container form-details__container--background-color global-margin--none">
        <form
                [formGroup]="formGroup"
                (ngSubmit)="onSubmit()">
            <div class="form-details__field">
                <label for="email">{{ 'user-self-management.email' | translate }}</label>
                <input
                        id="email"
                        type="email"
                        formControlName="email"
                        placeholder="{{'user-self-management.type-email' | translate}}">
                <span
                        [ngClass]="emailControl.invalid && (emailControl.dirty || emailControl.touched) ? 'visibility--true' : 'visibility--false'"
                        class="global-error-message"
                >{{ 'common.field-required' | translate }}</span>
            </div>
            <div class="form-details__field">
                <label for="password">{{ 'user-self-management.password' | translate }}</label>
                <input
                        id="password"
                        type="password"
                        formControlName="password"
                        placeholder="{{'user-self-management.type-password' | translate}}">
                <span
                        [ngClass]="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched) ? 'visibility--true' : 'visibility--false'"
                        class="global-error-message"
                >{{ 'common.field-required' | translate }}</span>
            </div>

            <div class="global-flex global-justify__center">
                <button
                        type="submit"
                        [disabled]="formGroup.invalid"
                >{{ 'common.submit' | translate }}
                </button>
                <button
                        type="button"
                        (click)="onCancel()"
                >{{ 'common.return' | translate }}
                </button>
            </div>

        </form>
    </div>

</div>

