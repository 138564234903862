<div class="global-flex global-flex-direction__column global-align__items-center global-justify__center">
  <h1 class="title2">{{errorTitle}}</h1>

  <div class="error-message-page">
    <div class="error-message-page__header">
      <h2>{{errorGuideMessage}}</h2>
    </div>

    <div class="error-message-page__message"
         [ngClass]="displayFullMessage ? 'error-message-page__message--expanded' : 'error-message-page__message--collapsed'">
      <p>{{('common.error-code' | translate) + ':\n' + errorCode}}</p>
      <p>{{('common.error-message' | translate) + ':\n' + (errorMessage | translate)}}</p>
    </div>

    <div class="error-message-page__action">
      <button
        class="error-message-page__action__display-button"
        (click)="toggleMessageVisibility()">{{(displayFullMessage ? 'common.hide' : 'common.show-full-message') | translate}}</button>
      <button
        class="error-message-page__action__close-button"
        (click)="onClose()">{{'common.return-to-homepage' | translate}}</button>
    </div>

  </div>
</div>
