<div class="navbar-bh"
     data-cy="navbar-div">

  <img
    class="navbar-bh__app-logo"
    alt="Go to HomePage"
    src="assets/logo_left_white.png"
    [ngClass]="[isTerminalDevice ? 'navbar-bh__app-logo--terminal' : 'navbar-bh__app-logo--desktop', isMobileDevice ? 'global-big-hero-img' : '']"
    (click)="goToHomePage()">

  <div
    *ngIf="!isMobileDevice"
    class="navbar-bh__functionalities-container"
  [ngClass]="isTerminalDevice ? 'navbar-bh__functionalities-container--terminal' : 'navbar-bh__functionalities-container--desktop'">
    <div class="navbar-bh__functionalities"
         *ngFor="let functionalityGroup of getCurrentFunctionalityGroups()"
         [ngClass]="isTerminalDevice ? isActiveFunctionalityGroup(functionalityGroup) ?
          'navbar-bh__functionalities--terminal active-functionality' : 'navbar-bh__functionalities--terminal'
          : isActiveFunctionalityGroup(functionalityGroup) ? 'navbar-bh__functionalities--desktop active-functionality' : 'navbar-bh__functionalities--desktop' ">
      <div (click)="redirectTo(functionalityGroup)" class="functionality">
        <span>{{getFunctionalityGroupName(functionalityGroup) | translate}}</span>
      </div>
    </div>
  </div>


  <div class="navbar-bh__user-logo-dropdown">
    <div class="global-pointer navbar-bh__margin">
      <div class="dropdown"
           [ngClass]="isMobileDevice ? 'global-flex global-justify__flex-end' : ''">
        <ng-container *ngIf="isMobileDevice; then hamburgerMenu else avatarMenu "></ng-container>

        <ng-template #hamburgerMenu>
          <img
            class="global-width--40"
            src="assets/hamburger-menu.png"
            alt="hamburger menu">
        </ng-template>

        <ng-template #avatarMenu>
          <app-secured-image [class]="avatarStyleClass" [src]="userAvatarUrl"></app-secured-image>
        </ng-template>

        <div
          class="dropdown-content"
          [ngClass]="isMobileDevice ? 'global-top__3-rem global-padding__small' : ''">
          <button
            class="dropdown-item"
            type="button"
            [ngClass]="isTerminalDevice ? 'dropdown-item--terminal' : ''"
            [ngStyle]="{'font-size' : isMobileDevice ? 'large' : ''}"
            [routerLink]="['/bh/profile']">{{'common.user-profile' | translate}}
          </button>
          <button
            class="dropdown-item"
            type="button"
            [ngClass]="isTerminalDevice ? 'dropdown-item--terminal' : ''"
            [ngStyle]="{'font-size' : isMobileDevice ? 'large' : ''}"
            (click)="aboutModal.toggleModal()">{{'common.about' | translate}}
          </button>
          <button
            class="dropdown-item"
            type="button"
            [ngClass]="isTerminalDevice ? 'dropdown-item--terminal' : ''"
            [ngStyle]="{'font-size' : isMobileDevice ? 'large' : ''}"
            (click)="logout()">{{'common.logout' | translate}}
          </button>
        </div>
      </div>
      <app-about #aboutModal></app-about>
    </div>
  </div>
</div>

<div
  *ngIf="isMobileDevice"
  class="functionality-menu">
  <p-panel
    header="functionalities"
    toggler="header"
    [ngClass]="'global-width--100'"
    [toggleable]="true"
    [(collapsed)]="collapsedFunctionalities">
    <div
      *ngFor="let functionalityGroup of getCurrentFunctionalityGroups()"
      class="functionality-menu__items-container">
      <div
        class="functionality-menu__items-container__items"
        [ngClass]="isActiveFunctionalityGroup(functionalityGroup) ? 'active-functionality' : ''"
        (click)="redirectTo(functionalityGroup)">
        <div class="functionality">
          <span>{{getFunctionalityGroupName(functionalityGroup) | translate}}</span>
        </div>
      </div>
    </div>
  </p-panel>
</div>
