<div
  class="global-flex global-flex-direction__row global-justify__center global-align__items-center global-padding__small-bottom">
  <div
    class="global-padding__small-right"
    [ngSwitch]="getDefaultLanguage()">
    <img class="global-logo"
         *ngSwitchCase="'en_GB'"
         src="assets/uk_flag.png">
    <img class="global-logo"
         *ngSwitchCase="'de_DE'"
         src="assets/german_flag.png">
  </div>

  <p-dropdown class="global-input primeng-dropdown-override"
    *ngIf="languages"
    [options]="languages" (onChange)="onSelect($event)" [(ngModel)]="translateService.currentLanguage">
    <ng-template let-language pTemplate="selectedItem">
      <div>{{ 'common.' + language.label | translate}}</div>
    </ng-template>
    <ng-template let-language pTemplate="item">
      <div>
        <div>{{ 'common.' + language.label | translate}}</div>
      </div>
    </ng-template>
  </p-dropdown>

</div>



