import {Component, OnInit} from '@angular/core';
import {ErrorMessageService} from '../../shared/services/error-message.service';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    standalone: false
})
export class ErrorMessageComponent implements OnInit {

  public errorCode: number;
  public errorMessage: string;
  public errorTitle: string = 'common.something-went-wrong';
  public errorGuideMessage: string = 'common.if-error-occurs-inform-administrator';
  public displayFullMessage = false;
  private TEAPOT_CODE = 418;
  private TEAPOT_MESSAGE = 'common.no-error-message-provided';

  constructor(private errorMessageService: ErrorMessageService) {
  }

  public ngOnInit(): void {
    this.setClassFields();
  }

  private setClassFields(): void {
    const errorData: { errorCode: number, errorMessage: string } = this.errorMessageService.errorData;
    this.errorCode = errorData?.errorCode ?? this.TEAPOT_CODE;
    this.errorMessage = errorData?.errorMessage ?? this.TEAPOT_MESSAGE;
  }

  public onClose(): void {
    window.history.back();
  }

  public toggleMessageVisibility(): void {
    this.displayFullMessage = !this.displayFullMessage;
  }

}
