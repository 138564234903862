import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {VersionCtrl} from '../../../core/sdk/bighero-controllers';
import {Version} from '../../../core/sdk/model-dto';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    standalone: false
})

// TODO To be removed or refactored
export class AboutComponent implements OnInit {
  public modalOpened: boolean;

  @ViewChild('modalAbout') modalAbout: ElementRef;

  public version: Version;

  constructor(private httpClient: HttpClient,
              private versionCtrl: VersionCtrl) {
  }

  public ngOnInit(): void {
    this.getVersionFromServer();
    this.modalOpened = false;
  }

  public getVersionFromServer(): void {
    this.versionCtrl.getServerVersion().subscribe(data => {
      this.version = data;
    });
  }

  public getObjectKeys(): string[] | null {
    return null;
  }

  public toggleModal(): void {
    this.modalOpened = !this.modalOpened;
  }

}
