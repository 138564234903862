import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthPreloadStrategy implements PreloadingStrategy {

  constructor(private authService: AuthService) {
  }

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    return this.authService.isAuthenticated() ? fn() : of(null);
  }

}
